<template>
<div>
  <div v-for="(item,i) in items" :key="i">
    <!-- LIST ITEM-->
    <v-hover #default="{hover}">
      <v-sheet
          @click="itemClickAction(item , i)"
          class="pointer "
          v-ripple
          :color="!hover ? (isSelected(item) ? (wsACCENT + '33') : 'transparent') : (wsACCENT + '22')"
          style="transition: background-color 0.3s ease; position: relative"
          :height="!item.image_preview ? 40 : 60"
      >

        <ws-link :to="item.path" style="width: 100%" >
          <div class="d-flex justify-space-between align-center fill-height"  >
            <div class="d-flex align-center fill-height" style="width: 100%">
              <!-- Selection Design-->
              <v-sheet height="36" color="transparent" class="d-flex justify-space-between align-center" style="padding : 6px 0" width="38">
                <v-sheet min-width="5" width="5" color="transparent" class="fill-height">
                  <v-sheet
                      v-if="isSelected(item)"
                      style="border-bottom-right-radius: 4px; border-top-right-radius: 4px;" min-width="5" width="5"  class="fill-height" :color="wsACCENT" />
                </v-sheet>

                <!-- Expand Icon-->
                <v-icon v-if="item.children" class="mr-n1" :style="item.expanded ? 'transform: rotate(90deg)' : null" style=""  :color="wsACCENT">mdi-menu-right</v-icon>

              </v-sheet>
              <!-- Child Design -->
              <v-sheet v-if="isChild"   min-height="100%"  width="41" style="position:relative; margin-left: 10px"   color="transparent" >
                <v-sheet v-if="i < (items.length - 1)" style="position: absolute;" color="transparent"  :style="`border-left : 1px solid ${wsDARKLIGHT} !important;`"  height="40" width="20" />
                <v-sheet color="transparent" style="border-bottom-left-radius: 50%" :style="`border-left : 1px solid ${wsDARKLIGHT} !important; border-bottom : 1px solid ${wsDARKLIGHT} !important`"  height="20" width="20"   />
              </v-sheet>
              <!-- Icon-->
              <v-icon v-if="item.icon && !isChild" style="margin-left: 1px; margin-right: 17px" :color="itemIconColor(item)">{{  item.icon  }}</v-icon>
              <!-- Image-->
              <div style=""  v-if="item.image_preview" >
                <v-sheet
                    v-if="!item.img"
                    width="32" height="32" :color="wsDARKLIGHT" class="d-flex align-center justify-center wsRoundedLight ml-n3 mr-3">
                  <v-icon color="#ffffff">mdi-image</v-icon>
                </v-sheet>
                <v-img
                    v-if="item.img "
                    :src="item.img"
                    class="wsRoundedLight  ml-n3 mr-3"
                    height="32"
                    width="32"
                    transition="xxx"
                />
              </div>

              <!-- Title-->
              <div style="width: 100%">
                <h5 class="shorten-text "  :style="titleStyle(item)"> {{ item.text }} </h5>
                <div  v-if="item.display_draft" class="d-flex">
                  <v-sheet  :color="wsDARKLIGHT" class="wsRoundedLight d-flex py-1 px-2 mt-1" dark >
                    <h6 style="color : #ffffff">{{ $t('Draft') }}</h6>
                  </v-sheet>
                </div>
                <h5 v-if="!item.display_draft && item.date" class="font-weight-regular" :style="`color : ${wsACCENT}`">{{  MONTH_DAY_TIME( item.date  )  }}</h5>
              </div>


            </div>


            <div style="width: 40px" >
              <!-- Edit Button-->
              <ft-select
                  v-if="!(noEdit || item.no_edit) && (hover || expandSettingsData[item.value]) && (!item.children || item.is_folder ) && !item.value.toString().includes('no-lang_')"
                  @input="pageAction( $event , item )"
                  :items="getPageActionsSelect(item)"
                  @expand="handleSettingsExpand($event, item.value)"
                  left
                  :icon-color="wsATTENTION"
                  :key="i + 'settings'"
              >

                <v-btn
                    @click.prevent
                    :style="item.hover ? `background-color : ${wsACCENT + '66'}` : null"
                    light
                    :color="wsDARKER"
                    text
                    class="noCaps px-2 mr-3"
                    width="30"
                    min-width="30"
                    height="30"
                >
                  <v-icon :color="wsACCENT">mdi-dots-vertical</v-icon>
                </v-btn>

              </ft-select>
            </div>

          </div>
        </ws-link>
      </v-sheet>

    </v-hover>



    <!-- CHILDREN LIST ITEMS-->
    <v-expand-transition>
      <div v-if="item.children && expandItemsData[item.value] && !isChild">

        <ws-avalon-navigation-list
            v-model="selectedItem"
            :items="item.children"
            mandatory
            is-child
        />

      </div>
    </v-expand-transition>
  </div>
</div>


</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "wsAvalonNavigationList",
  props : {
    value : {
      default : ''
    },
    items : {
      type : Array,
      default() { return [] }
    },
    mandatory : {
      type : Boolean,
      default : false
    },
    noEdit : {
      type : Boolean,
      default : false
    },
    customAction : {
      type : Boolean,
      default : false
    },
    isChild : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      settingsHover : false,
      selectedItem : '',
      expandItemsData : {},
      expandSettingsData : {},
    }
  },
  watch : {
    selectedItem() {
      if ( this.value !== this.selectedItem ) {
        this.handleOpenFolder()
        this.$emit('input' , this.selectedItem )
      }
    },
    value() {
      if ( this.value !== this.selectedItem ) {
        this.selectedItem = this.value
        this.handleOpenFolder()
      }
    },
    items : {
      handler() {
        this.prepareExpandData()
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalon' , [ 'UNPUBLISH_PAGE' , 'SAVE_CHANGES' ]),

    handleOpenFolder() {
      let folders = this.items.filter(el => el.children && el.children.filter(child => child.value.toString() === this.selectedItem.toString()).length > 0)
      if ( folders.length > 0 ) {
        folders.forEach(folder => {
          this.expandItemsData[folder.value] = true
        })
        this.expandItemsData = this.COPY(this.expandItemsData)
      }
    },

    openEditFolder(item) {
      this.$store.state.avalon.selectedFolder = this.COPY(item)
      this.$store.state.avalon.displayEditFolderDialog = true
    },
    openDeleteFolder(item) {
      this.$store.state.avalon.selectedFolder = this.COPY(item)
      this.$store.state.avalon.displayDeleteFolderDialog = true
    },

    titleStyle(item) {
      let color = this.wsACCENT
      if ( (item.is_course && !item.page_id)  ) {
        color = this.wsDARKLIGHT
      }
      if ( item.color ) {
        color = item.color
      }
      return `color : ${color}`
    },

    itemClickAction(item) {


      if ( item.children && item.children.length > 0 && !item.disable_expand ) {
        this.expandItemsData[item.value] = !this.expandItemsData[item.value]
      }

      this.selectedItem = item.value
       if ( typeof item.action === 'function')  {
         item.action()
       }

    },
    getPageActionsSelect(item) {
      let items = []
      if ( item.is_folder ) {
        items = [
          { text  :  this.$t('Edit')   ,
            value : 'edit_folder'   ,
            icon : 'mdi-pencil' ,
            action : () => this.openEditFolder(item)
          },
          { text  :  this.$t('Delete') ,
            value : 'delete_folder' ,
            icon : 'mdi-delete-outline' ,
            action : () => this.openDeleteFolder(item)
          },
        ]
      } else {
        items =  [
          { text  :  this.$t('Duplicate')   , value : 'copy'   , icon : 'mdi-content-copy'},
          { text  :  this.$t('Delete') , value : 'delete' , icon : 'mdi-delete-outline'},
          { text  :  this.$t( !item.is_published ? 'Publish' : 'UnPublish')   ,
            value : !item.is_published ? 'publish' : 'unpublish' ,
            icon : !item.is_published ? 'mdi-reload' : 'mdi-power'
          },
          { text  :  this.$t('Settings')   , value : 'edit'   , icon : 'mdi-cog'},
        ]
        if ( item.is_published ) {
          items.splice(   3,0,{
            text  :  this.$t('Link')   ,
            value : 'link'   ,
            icon : 'mdi-open-in-new'},)
        }
      }


      return items
    },
    handleSettingsExpand(expandValue , itemValue) {
      this.expandSettingsData[itemValue] = expandValue
    },
    async pageAction(action , item) {

      this.$emit('edit-action' , { action, item })

      if ( this.customAction ) {
        return
      }

      if ( action === 'edit' ) {
        this.$store.commit('avalonPage/OPEN_EDIT_PAGE' , item.page_id)
      }
      if ( action === 'copy' ) {
        this.$store.commit('avalonPage/OPEN_COPY_PAGE' , item.page_id)
      }
      if ( action === 'delete' ) {
        this.$store.commit('avalonPage/OPEN_DELETE_PAGE' , item.page_id)
      }
      if ( action === 'publish' ) {
        this.saveChanges(item.page_id)
      }
      if ( action === 'unpublish' ) {
        this.unPublishPage(item.page_id)
      }
      if ( action === 'link' ) {
        try {
          let text = this.businessPublicLink('page/' + item.alias , true )
          await navigator.clipboard.writeText(text);
          this.notify(this.$t('CopiedToClipboard') )
        } catch($e) {
          this.notify(this.$t('Error') )
        }
      }
    },

    async unPublishPage(pageId) {
      let result = await this.UNPUBLISH_PAGE(pageId)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      let index = this.$store.state.avalon.pagesSelect.findIndex(el => el.value === pageId )
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.pagesSelect[index].is_published = false
      this.$store.state.avalon.pagesSelect = this.COPY(this.$store.state.avalon.pagesSelect)

      this.notify(this.$t('PageUnPublished') )
    },

    async saveChanges(pageId) {
      let result = await this.SAVE_CHANGES(pageId)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      let index = this.$store.state.avalon.pagesSelect.findIndex(el => el.value === pageId )
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.pagesSelect[index].is_published = true
      this.$store.state.avalon.pagesSelect = this.COPY(this.$store.state.avalon.pagesSelect)
      this.notify(this.$t(result.activated ? 'PagePublished' : 'ChangesPublished') , 'success')
    },

    isSelected(item) {
      return this.selectedItem === item.value
    },
    prepareExpandData() {
      this.expandItemsData = {}
      this.expandSettingsData = {}
      this.items.forEach((item) => {
        if ( item.children  ) {
          this.expandItemsData[item.value] = item.expanded || false
        }
        this.expandSettingsData[item.value] = false
      })
      this.expandItemsData = this.COPY(this.expandItemsData)
      this.expandSettingsData = this.COPY(this.expandSettingsData)
    },
    itemIconColor(item) {

      if (item.children && item.children.length === 0) {
        return this.wsDARKLIGHT
      } else if ( item.alias && !item.is_published ) {
        return this.wsDARKLIGHT
      }
      return this.wsACCENT

    }
  },
  mounted() {
    this.prepareExpandData()
    if ( this.value ) {
      this.selectedItem = this.value
    }
  }
}
</script>

<style scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>