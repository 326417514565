<template>
  <v-sheet color="transparent" class="px-4" style="width: 100%; position: relative">

    <!-- Categories-->
    <tiles-categories
        style="width: 100%"
        v-if="block.config.display_categories && !block.config.category"
        @input="selectCategory"
        :value="selectedCategory"
        :items="allCategoriesSelect"
        :block="block"
    />

    <!-- Sub Categories-->
    <v-expand-transition>
      <tiles-categories
          v-if="subcategoriesSelect.length > 0 && block.config.display_categories && !block.config.category"
          v-model="selectedSubcategory"
          :items="subcategoriesSelect"
          :block="block"
      />
    </v-expand-transition>

    <!-- Tiles Content-->
    <v-fade-transition mode="out-in">
      <div   style="width : 100%">
        <v-row
            v-if="!isSingleColumnDesign"
            :key="currentPage"
            :style="rowStyle"
            no-gutters
        >
          <v-col
              :style="getColStyle(i)"
              v-for="(item , i) in itemsFiltered" :key="i"
              :cols="12"
              :md="cols"

          >

            <template v-if="source === 'news'">
              <tiles-news-design1
                  v-if="design === 'tiles' "
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :preview="preview"
              />

            </template>

            <template v-else-if="source === 'courses'">
              <tiles-courses-design
                  v-if="design === 'tiles' "
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :preview="preview"
                  @open-course-dialog="openCourseDialog"
                  :default-site="defaultSite"
              />
            </template>
          </v-col>
        </v-row>

        <template v-else>
          <template  v-for="(item , i) in itemsFiltered"  >

            <template v-if="source === 'news'">

              <list-big-news-design
                  v-if="design === 'list_big'" :key="i"
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :index="i"
                  :preview="preview"
                  :default-site="defaultSite"
              />


              <list-small-news-design
                  v-if="design === 'list_small'" :key="i"
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :index="i"
                  :preview="preview"
                  :default-site="defaultSite"
              />


              <list-no-images-news-design
                  v-if="design === 'list_no_image'" :key="i"
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :index="i"
                  :preview="preview"
                  :default-site="defaultSite"
              />

            </template>
            <template v-if="source === 'courses'">

              <list-big-courses-design
                  v-if="design === 'list_big' "
                  @open-course-dialog="openCourseDialog"
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :index="i"
                  :key="i"
                  :preview="preview"
                  :default-site="defaultSite"
              />
              <list-small-courses-design
                  v-if="design === 'list_small' "
                  @open-course-dialog="openCourseDialog"
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :index="i"
                  :key="i"
                  :preview="preview"
                  :default-site="defaultSite"
              />
              <list-no-image-courses-design
                  v-if="design === 'list_no_image' "
                  @open-course-dialog="openCourseDialog"
                  :item="item"
                  :config="block.config"
                  :block="block"
                  :cols="cols"
                  :index="i"
                  :key="i"
                  :preview="preview"
                  :default-site="defaultSite"
              />
            </template>

          </template>

        </template>

      </div>

    </v-fade-transition>
    <!-- Pagination -->
    <div v-if="block.config.pagination"
        class="d-flex justify-center flex-wrap mt-10"
    >


      <v-sheet
          v-if="displayLoadMore"
          style="border-radius: 100px"
          @click="loadMore"
          class="px-6 py-2 mr-3 pointer "
          v-ripple
          :color="BLOCK_COLOR_PARAM(block ,'button_bg' , block.config.style )"
      >
        <h5 :style="buttonStyle(selectedCategory)">{{  $t('loadMore')  }} </h5>
      </v-sheet>


    </div>
    <!-- All items Page Button -->

    <div  v-if="!block.config.pagination && !!block.config.all_items_page"
          class="d-flex justify-center">
      <router-link
          :is="preview || $store.state.avalon.editorMode ? 'span' : 'router-link'"
          :to="allItemsPageRoute" class="noUnderline">
        <v-sheet
            style="border-radius: 100px"
            class="px-6 py-2 mr-3 pointer mt-10"
            v-ripple
            :color="BLOCK_COLOR_PARAM(block , 'button_bg' , block.config.style )"
        >
          <h5 :style="buttonStyle(selectedCategory)">{{ block.config.all_items_page_text || $t('All')  }} </h5>
        </v-sheet>
      </router-link>
    </div>


    <!-- DIALOGS-->
    <viewButtonCourseEnroll
        v-if="source === 'courses' && selectedCourse"
        :entity="selectedCourse"
        :display="displayCourseEnrollTrigger"
        :block="block"
        avalon
        :element-uuid="block.id + 'block'"
    />

  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";
import tilesCoursesDesign from "@/components/AvalonEditor/Editor/blocks/tiles/couresesDesigns/tilesCoursesDesign";
import listBigCoursesDesign from "@/components/AvalonEditor/Editor/blocks/tiles/couresesDesigns/listBigCoursesDesign";
import listSmallCoursesDesign
  from "@/components/AvalonEditor/Editor/blocks/tiles/couresesDesigns/listSmallCoursesDesign";
import listNoImageCoursesDesign
  from "@/components/AvalonEditor/Editor/blocks/tiles/couresesDesigns/listNoImageCoursesDesign";

import listBigNewsDesign from "@/components/AvalonEditor/Editor/blocks/tiles/newsDesigns/listBigNewsDesign";
import listNoImagesNewsDesign from "@/components/AvalonEditor/Editor/blocks/tiles/newsDesigns/listNoImagesNewsDesign";
import listSmallNewsDesign from "@/components/AvalonEditor/Editor/blocks/tiles/newsDesigns/listSmallNewsDesign";

import coursesPlaceholder from "@/assets/json/avalon/placeholder/courses_placeholder.json"
import newsPlaceholder from "@/assets/json/avalon/placeholder/news_placeholder.json"

import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";
import tilesCategories from "@/components/AvalonEditor/Editor/blocks/tiles/UI/tilesCategories";

export default {
  name: "blockDynamicTiles",
  components : {
    tilesNewsDesign1 : () => import("@/components/AvalonEditor/Editor/blocks/tiles/newsDesigns/tilesNewsDesign1"),
    tilesCoursesDesign,
    listBigCoursesDesign,
    listSmallCoursesDesign,
    listNoImageCoursesDesign,
    listBigNewsDesign,
    listNoImagesNewsDesign,
    listSmallNewsDesign,
    viewButtonCourseEnroll,
    tilesCategories
  },
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    preview : {
      type : Boolean,
      default : false
    },
    defaultSite : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      items : [],
      selectedCategory : null,
      selectedSubcategory : null,
      categoriesSelect : [],
      selectedPage : 0,
      currentPage : 0,
      displayLoadMore : false,
      columnsSizes : {
        1 : 12,
        2 : 6,
        3 : 4,
        4 : 3,
        6 : 2,
        12 : 1
      },

      // courses
      selectedCourse : null,
      displayCourseEnrollTrigger : 0
    }
  },
  computed : {
    ...mapState('avalon' , ['editorOn' , 'selectedLang']),

    itemsToDisplay() {
      return (this.selectedPage + 1) * (this.block.config.items_per_page || 3)
    },
    isSingleColumnDesign() {
      return [
        'list_small',
        'list_big',
        'list_no_image'
      ].includes(this.block.config.design)
    },
    design() {
      return this.block.config.design || 'tiles'
    },

    rowStyle() {
      let style = 'max-width: 100%; width: 100%;'
      if ( this.block.config.gutter && !( this.SM || this.MOBILE_VIEW ) ) {
        style += `margin-left : -${this.block.config.gutter/2}px;`
        style += `margin-right : -${this.block.config.gutter/2}px;`
      }
      return style
    },

    cols() {
      if ( this.isSingleColumnDesign ) {
        return 12
      }
      return !this.MOBILE_VIEW ? this.columnsSizes[this.block.config.columns_large || 3] : 12
    },
    allItemsPageRoute() {
      if ( this.CURRENT_LANG_PAGES.length === 0 ) {
        return null
      }
      if ( this.editorOn )  {
        return this.businessPublicLink(`page/${this.block.config.all_items_page}/editor`)
      } else {
        let item = this.CURRENT_LANG_PAGES.find(el => el.value === this.block.config.all_items_page )
        let alias = item.alias
        return this.businessPublicLink(`page/${alias}`)
      }
    },

    source() {
      switch ( this.block.type ) {
        case 'tiles_news'  : return 'news'
        case 'tiles_courses'  : return 'courses'
        default :  return 'news'
      }
    },
    config() {
      return this.block.config || {}
    },
    allCategoriesSelect() {
      if ( this.preview ) {
        items = [
          { text : "All courses" , value : "all" },
          { text : "Design" , value : "" },
          { text : "Copyright" , value : "" },
          { text : "Programming" , value : "" },
          { text : "Testing" , value : "" }
        ]
        return items
      }
      let items = this.COPY(this.categoriesSelect)
      items = items.filter(el => !el.parent)
      items.unshift({ text : this.$t('All') , value : null })
      return items
    },
    subcategoriesSelect() {
      if ( !this.selectedCategory || !this.categoriesSelect ) {
        return []
      }
      return this.COPY(this.categoriesSelect).filter(el => el.parent === this.selectedCategory)

    },
    itemsFiltered() {

      let items = this.COPY(this.items)

      if ( this.selectedCategory || this.selectedSubcategory ) {
        items = items.filter( el => el.categories &&  el.categories.includes(this.selectedSubcategory || this.selectedCategory))
      }

      return items
    },
    nextIsAvailable() {
      if ( !this.block.config.elements ) {
        return false
      }
      return this.block.config.elements.length > (this.currentPage*this.block.config.items_per_page + this.block.config.items_per_page )
    },
    previousIsAvailable() {
      return this.currentPage !== 0
    },
    itemsPerPage() {
      return this.block.config.items_per_page || 3
    },
    blockCategory() {
      return this.block.config.category || null
    }
  },
  watch : {
    LANG() {
      this.initItems()
    },
    selectedCategory() {
      if ( this.preview ) {
        return
      }

      this.selectedPage = 0
      this.initItems()
    },
    selectedSubcategory() {
      if ( this.preview ) {
        return
      }

      this.selectedPage = 0
      this.initItems()
    },
    blockCategory(value) {
      if ( this.preview ) {
        return
      }

      this.selectedPage = 0
      this.selectedCategory = value
      this.initItems()
    },
    source() {
      if ( this.preview ) {
        return
      }

      this.selectedPage = 0
      this.initItems()
    },
    itemsPerPage() {
      if ( this.preview ) {
        return
      }

      this.selectedPage = 0
      this.initItems()
    }
  },
  methods : {
    ...mapActions('avalonPage' , ['GET_DYNAMIC_BLOCK_ITEMS' , 'GET_DYNAMIC_BLOCK_CATEGORIES']),

    selectCategory(value) {
      let children = []

      if (value) {
         children = this.categoriesSelect.filter(el => el.parent === value)
      }

      if (children && children.length > 0  ) {
        this.selectedSubcategory = children[0].value
      } else {
        this.selectedSubcategory = value
      }

      this.selectedCategory = value

    },
    // courses
    openCourseDialog(course) {
      if ( this.preview) {
        return
      }
      // if ( this.$store.state.avalon.editorMode ) {
      //   return
      // }
      this.selectedCourse = course
      setTimeout(() => {
        this.displayCourseEnrollTrigger++
      },10)

    },

    getColStyle(index ) {
      let style = 'position : relative;'
      if ( this.block.config.gutter_vertical &&
          (!(this.SM || this.MOBILE_VIEW) && index >= this.block.config.columns_large )
          || ( (this.SM || this.MOBILE_VIEW) && index > 0 )
      ) {
        style += `margin-top : ${this.block.config.gutter_vertical}px;`
      }
      if ( this.itemsFiltered.length > 1 && this.block.config.gutter && !(this.SM || this.MOBILE_VIEW) ) {
        style += `padding-left : ${this.block.config.gutter/2}px;`
        style += `padding-right : ${this.block.config.gutter/2}px; `
      }
      return style
    },

    loadMore() {
      this.selectedPage++
      this.initItems(this.selectedPage)

    },
    buttonStyle(category , subcategory = false) {
      let style = this.BLOCK_TEXT_CSS(this.block , this.block.config.style , 'buttons' , 'text')
      style = style.slice(13)

      style += `color : ${ this.BLOCK_COLOR_PARAM(this.block ,  category === (!subcategory ? this.selectedCategory : this.selectedSubcategory) ? 'button_text' : 'button_text_secondary' , this.block.config.style  ) };`

      return style
    },

    nextPage() {
      if ( !this.nextIsAvailable ) {
        return
      }
      this.currentPage++
    },
    previousPage() {
      if ( !this.previousIsAvailable ) {
        return
      }
      this.currentPage--
    },
    initPreviewContent(forceInit = false) {
      if ( this.defaultSite ) {
        return
      }
      if ( !this.preview && !forceInit ) {
        return
      }
      if ( this.source ==='courses') {
        this.items = coursesPlaceholder
        this.items.splice(3,3)
      }
      if ( this.source ==='news') {
        this.items = newsPlaceholder
        this.items.splice(3,3)
      }

    },
    async initItems(page = 0) {
      if ( this.preview ) {
        return
      }
      if ( !this.source ) {
        this.items = []
        return
      }

      if ( this.categoriesSelect.length === 0 ) {
        let result = await this.GET_DYNAMIC_BLOCK_CATEGORIES({ source : this.source , lang : this.$store.state.avalon.editorMode ? this.selectedLang : null })
        if ( result && result !== true ) {
          this.categoriesSelect = result
        }
      }

      let offset = page * (this.block.config.items_per_page || 9)
      let data = {
        lang : !this.$store.state.editorMode ? this.LANG :  this.selectedLang,
        source : this.source,
        items : this.block.config.items_per_page || 3,
        offset : offset,
        category : this.selectedSubcategory || this.selectedCategory
      }
      let result = await this.GET_DYNAMIC_BLOCK_ITEMS(data)
      if ( !result  ) {
        return
      }
      if ( this.selectedPage === 0 ) {
        this.items = result.items
      } else {
        result.items.forEach(el => {
          this.items.push(el)
        })
      }
      this.displayLoadMore = !result.is_last

      if ( this.items.length === 0 ) {
        this.initPreviewContent(true)
      }


    }
  },
  beforeMount() {
    this.initPreviewContent()
  },
  async mounted() {
    if ( this.preview ) {
      return
    }

    if ( this.block.config.category) {
      this.selectedCategory = this.block.config.category
    }


    await this.initItems()
    if ( this.defaultSite && this.items.length === 0 ) {
      this.$emit('no-items')
    }

    if ( this.source !== 'courses') {
      return
    }

    let elementRefreshAuth = localStorage.getItem('avalon_refresh_element')
    if ( !elementRefreshAuth ) {
      return
    }
    let split = elementRefreshAuth.split('/')

    let course = split[0]
    elementRefreshAuth = split[1]

    let courseEntity = this.items.find( el=> el.uuid === course)
    if (this.loggedIn && course && courseEntity && course === courseEntity.uuid && elementRefreshAuth && String(elementRefreshAuth) === String(this.block.id + 'block') ) {
      localStorage.removeItem('avalon_refresh_element')
      this.openCourseDialog(this.COPY(courseEntity))
    }

  }
}
</script>

<style scoped>

</style>