<template>
  <!-- Navigation -->
  <div v-if="!value && !custom">
    <v-hover
        v-for="(item , index) in items" :key="index"
        #default="{hover}"
    >
      <v-sheet
          v-if="!item.hidden"
          @click="selectAction(item)"
          :style="`box-shadow : 0 0 0 ${hover ? 3 : 1}px ${wsBACKGROUND}`"
          class="d-flex pointer justify-space-between wsRoundedLight mb-2 pl-5  pa-3">

        <div class="d-flex align-center">
          <v-icon v-if="item.icon" :color="wsACCENT" class="mr-2">{{ item.icon }}</v-icon>
          <h4 class="shorten-text">{{  item.text  }}</h4>
        </div>

        <v-icon :color="wsACCENT">mdi-menu-right</v-icon>
      </v-sheet>
    </v-hover>
  </div>
  <div v-else-if="!value && custom">
    <slot name="custom"></slot>
  </div>
  <!-- Selected Items -->
  <div v-else>

    <!-- Return Back Button-->
    <portal v-if="!hideReturn" to="block_settings_return" :disabled="!portal">
      <div
          class="d-flex justify-space-between align-center px-5 pr-7 pt-5 ">
        <h5 @click="returnTriggered"
            :style="`color : ${wsACCENT}`"
            class="pointer text-no-wrap"
        >
          <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-left</v-icon>
          {{ $t('GoBack') }}
        </h5>
        <h5 class="text-right shorten-text">{{ selectedItemText }}</h5>
      </div>
    </portal>

    <!-- Block Settings Portal Padding fix -->
    <div v-if="addTopPadding" class="pb-10"></div>

    <!-- Selected items content -->
    <template  v-for="(item , index) in items">
      <div :key="index + 'item'">
        <slot  name="item" :item="item">
          <div v-if="item.value === value " :key="index + 'item.content'" >
            <slot  :name="'item.' + item.value"></slot>
          </div>
        </slot>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: "blockSettingsNavigation",
  props : {
    value : {},
    items : {
      type : Array,
      default() { return [] }
    },
    portal : {
      type : Boolean,
      default : false
    },
    noInput:  {
      type : Boolean,
      default : false
    },
    addTopPadding : {
      type : Boolean,
      default : false
    },
    returnAction : {
      type : Function,
      default : null
    },
    custom : {
      type : Boolean,
      default : false
    },
    hideReturn : {
      type : Boolean,
      default : false
    }
  },
  computed : {
    selectedItemText() {
      if (!this.value) {
        return null
      }
      return this.items.find(el => el.value === this.value ).text || null
    }
  },
  methods : {
    selectAction(item) {
      this.$emit('select' , item.value)
      if ( !this.noInput ) {
        this.$emit('input', item.value)
      }
    },
    returnTriggered() {
      if ( !this.returnAction ) {
        this.$emit('input' , null)
        return
      }
      this.returnAction()
    }
  }
}
</script>

<style scoped>

</style>