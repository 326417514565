<template>

  <div>
    <slot></slot>
    <div v-if="displayEnrollSuccess || displayEnroll || displayPurchase || displayAuth">

      <portal :disabled="isDashoard" to="dialog_top">
        <div  id="course_enroll_dialogs">
          <!-- Enrollment Success Dialog on Course -->
          <!-- WeStudy Design-->
          <wsDialog v-model="displayEnrollSuccess"
                    :portal="false"
                    :loading="$store.state.LOADING"
                    @save="goToCourse"
                    :title="displayApplicationReceivedText ? $t('CourseApplicationSuccess') : $t('EnrollmentSuccess')"
                    :cancel-text="$t('Close')"
                    :show-save="!displayApplicationReceivedText"
                    :saveText="!displayApplicationReceivedText ? $t('GotoCourse') : $t('Ok')">

            <h5 class="font-weight-medium text-center mb-3">{{ displayApplicationReceivedText ?   $t('CourseApplicationSuccessMessage') : $t('EnrollmentSuccessMessage') }}</h5>

          </wsDialog>

          <!-- Enroll on Course -->
          <!-- WeStudy -->
          <wsDialog
              v-model="displayEnroll"
              :portal="false"
              v-if="displayEnroll && !avalon"
              :is="noDialog ? 'v-sheet' : 'ws-dialog'"
              :class="noDialog ? `wsRoundedHalf pt-3 px-6` : null"
              :width="!noDialog && !SM ? '700' : null"
              :loading="$store.state.LOADING"
              @save="enrollOnCourse"
              :title="$t('EnrollOnCourse')"
              :saveText="$t('Enroll')"
          >

            <!-- Scheduled course -->
            <div v-if="entity.scheduled">
              <div v-if="!SM">
                <h5 class="font-weight-medium mb-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
                <v-simple-table >
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th :style="`color : ${wsDARK}`"> {{ $t('Education Start') }}</th>
                    <th :style="`color : ${wsDARK}`"> {{ $t('Education End') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                      @click="enrollmentData.enrollment_wave_id = wave.uuid"
                      class="pointer">
                    <td>
                      <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                             :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                             small icon
                             class="mt-1">
                        <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <h5>{{ wave.name }}</h5>
                    </td>

                    <td>
                      <v-chip class="my-1 mr-2" small dark :color="wsDARK"  >
                        <v-icon small class="mr-1" > mdi-calendar</v-icon>
                        <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                      </v-chip>
                    </td>
                    <td>
                      <v-chip class="my-1 mr-2" small outlined   >
                        <v-icon small class="mr-1" > mdi-calendar</v-icon>
                        <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                      </v-chip>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </div>
              <div v-else>
                <h5 class="font-weight-medium mb-3 mt-6 text-center">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
                <v-sheet  v-for="(wave,i) in enrollmentWaves" :key="i"
                          @click="enrollmentData.enrollment_wave_id = wave.uuid"
                          :dark="enrollmentData.enrollment_wave_id === wave.uuid"
                          :color="enrollmentData.enrollment_wave_id === wave.uuid ? wsACCENT : ''"
                          class="pointer pa-3 mt-3 wsRoundedHalf" outlined>
                  <h5 class="text-center">{{ wave.name }}</h5>
                  <div class="d-flex justify-center">
                    <div>
                      <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                        <h5 class="font-weight-medium">{{ $t('EducationStart') }}: {{wave.education_start }}</h5>
                      </ws-chip>
                      <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                        <h5 class="font-weight-medium">{{ $t('EducationEnd') }}: {{wave.education_end }}</h5>
                      </ws-chip>
                    </div>

                  </div>

                </v-sheet>
              </div>

            </div>

            <div v-if="noDialog" class="d-flex justify-end pb-6 pt-10 ">
              <v-btn
                  @click="enrollOnCourse"
                  :color="wsATTENTION"
                  :block="SM"
                  class="noCaps"
                  dark
              >
                {{  $t('Enroll') }}
              </v-btn>
            </div>


          </wsDialog>
          <!-- Avalon -->
          <avalon-dialog
              v-model="displayEnroll"
              v-else-if="displayEnroll && avalon"
              :title="$t('EnrollOnCourse')"
              no-navigation
              fullscreen
              fullscreen-width="550"
              fullscreen-align="right"
              fill-height
              not-centered
              :block="block"
              :block-style="block.config.style"
          >

            <template #default>

              <v-sheet  class="align-center mb-6 " :class="[{'d-flex' : !SM }]" :color="GET_AVALON_COLOR('text_light')">
                <v-sheet :width="!SM ? `190` : null">
                  <v-img v-if="entity.img" :width="!SM ? `190` : null" height="170"   :src="entity.img" ></v-img>
                  <v-img v-else src="@/assets/img/course_blank.jpg" ></v-img>
                </v-sheet>
                <h4 :style="CONFIG_TEXT_CSS( block.style , 'h5' )" class="px-5 py-3 ">{{ entity.name }}</h4>
              </v-sheet>

              <!-- Enrollment Wave -->
              <div v-if="entity.online && entity.scheduled">
                <h5 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'label' )">
                  {{  $t('ChooseEnrollmentSchedule')  }}
                </h5>

                <v-sheet  color="transparent" :style="`border : 1px solid ${GET_AVALON_COLOR('text_dark')} !important`" class="py-1 mb-6">

                  <template v-for="(wave,i) in enrollmentWaves">
                    <div  :key="i + 'wave'"
                          @click="enrollmentData.enrollment_wave_id = wave.uuid"
                          class="pointer d-flex align-center py-1 px-3">
                      <v-icon size="16" :color="BLOCK_COLOR_PARAM(block , 'form_icon', null)" class="mr-4">{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'}}</v-icon>
                      <div>
                        <h5 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'text' )">{{ wave.name }}</h5>
                        <h4 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'text_large' )">{{ PARSE_DATE(wave.education_start)  }} - {{ PARSE_DATE(wave.education_end) }}</h4>
                      </div>
                    </div>
                    <v-divider :style="`border-color : ${BLOCK_COLOR_PARAM(block , 'form_border', null ) + '66'}`"  v-if="i < enrollmentWaves.length - 1"  :key="i + 'wave_divider'" />
                  </template>

                </v-sheet>
              </div>

            </template>

            <template #footer>
              <v-divider />
              <div style="padding: 15px 25px">
                <v-sheet :height="!SM ? `60` : `40`" class="mt-5">
                  <avalon-button
                      @click="enrollOnCourse"
                      :block-style="block.config.style"
                      fillArea
                      :text="$t('Enroll')"
                  />
                </v-sheet>
              </div>
            </template>

          </avalon-dialog>

          <!-- Course Purchase -->
          <!-- WeStudy -->
          <wsDialog
              v-model="displayPurchase "
              v-if="displayPurchase && !avalon"
              :is="noDialog ? 'v-sheet' : 'ws-dialog'"
              :class="noDialog ? `wsRoundedHalf pa-6 ` : null"
              :width="!noDialog && !SM ? '800' : null"
              :loading="$store.state.LOADING"
              @save="purchaseCourse"
              :title="!isSubscriptionExtend ? $t('PurchaseCourse') : $t('ExtendSubscription')"
              :saveText="purchaseText"
          >
            <div style="width: 100%" >
              <div class="pb-6">
                <!-- Desktop View-->
                <div v-if="!SM">
                  <v-simple-table v-if="!isSubscriptionExtend" >
                    <tbody>
                    <tr style="background: transparent" class="pointer"  >
                      <!-- Course Image -->
                      <td class="px-0 pl-3 py-3" width="1">
                        <v-img v-if="entity.img" class="wsRoundedHalf" height="120" width="200"  :src="entity.img" ></v-img>
                        <v-img v-else class="wsRoundedHalf" height="100" width="200"  src="@/assets/img/course_blank.jpg" ></v-img>
                      </td>
                      <!-- Course Name -->
                      <td valign="top" class="py-3">
                        <h4>{{ entity.name }}</h4>
                        <h5 class="font-weight-light mt-3">
                          {{ entity.short_description }}
                        </h5>
                      </td>
                      <!-- Course Price -->
                      <td valign="top" class="py-3" align="right"  width="150px">

                        <h4 class="text-no-wrap" :style="`color: ${wsDARK}`">{{ singlePriceText }} </h4>
                        <h5> {{ priceDescriptionSubscriptionText }}</h5>

                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                  <!-- Subscription Extend -->
                  <h4 v-else class="text-right mb-3"><span :style="`color : ${wsACCENT}`">{{ $t('ExtendTill') }}</span> : {{ subscriptionTill }}</h4>
                  <v-divider class="my-3" />

                  <div class="d-flex justify-space-between">
                    <div>
                      <div  v-if="!promocode" class="d-flex align-end">
                        <ws-text-field
                            v-model="promocodeInput"
                            :label="$t('PromoCode')"
                            clearable
                        />
                        <v-btn @click="getPromoCode" class="noCaps ml-2" height="40" outlined :color="wsACCENT">
                          {{ $t('Activate')}}
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </div>
                      <div v-else>
                        <h5 :style="`color : #4F5A6E; font-size: 13px; font-weight: 500 `" class="mb-2">  {{  $t('PromoCode')  }} </h5>
                        <h4 class="mt-2">
                          {{ promocode }}
                          <v-btn class="ml-4" @click="promocodeDiscount = null ; promocode = null; " icon >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </h4>
                      </div>
                    </div>
                    <div>
                      <h3 class="text-right">{{ $t('Total') }}: {{ totalPriceTextDiscount }} </h3>
                      <h4 v-if="discountQuantity > 0" class="text-right font-weight-light" style="text-decoration: line-through">{{ $t('OldPrice') }}: {{ totalPriceText }} </h4>
                      <h4 v-if="discountQuantity > 0" class="text-right" >{{ $t('Discount') }}: {{ discountQuantity }} % </h4>
                      <h4></h4>
                    </div>
                  </div>

                </div>
                <!-- Mobile View-->
                <div v-else>

                  <div v-if="!isSubscriptionExtend" >
            <span v-if="!noDialog">
              <v-img v-if="entity.img" class="wsRoundedHalf" height="180"   :src="entity.img" />
              <v-img v-else class="wsRoundedHalf" height="180"    src="@/assets/img/course_blank.jpg" />
            </span>
                    <v-sheet
                        v-else-if="entity.img"
                        class="wsRoundedHalf"
                        :style="`background : url('${entity.img }'); background-position : center`"
                        height="170" />

                    <h4 class="mt-3">{{ entity.name }}</h4>
                  </div>
                  <!-- Subscription Extend -->
                  <h4 v-else class="text-right mb-3"><span :style="`color : ${wsACCENT}`">{{ $t('ExtendTill') }}</span> : {{ subscriptionTill }}</h4>

                  <v-divider class="my-1"></v-divider>
                  <h4 class="text-right">{{ $t('Total') }}: {{ totalPriceTextDiscount }}  </h4>
                  <h5 v-if="entity.discount" class="text-right font-weight-light" style="text-decoration: line-through">{{ $t('OldPrice') }}: {{ totalPriceText }} </h5>
                  <h5 v-if="entity.discount" class="text-right" >{{ $t('Discount') }}: {{ entity.discount }} % </h5>

                  <div>
                    <div  v-if="!promocode" class="d-flex align-end">
                      <ws-text-field
                          v-model="promocodeInput"
                          :label="$t('PromoCode')"
                          clearable
                      />
                      <v-btn @click="getPromoCode" class="noCaps ml-2" height="40" outlined :color="wsACCENT">
                        {{ $t('Activate')}}
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <h5 :style="`color : #4F5A6E; font-size: 13px; font-weight: 500 `" class="mb-2">  {{  $t('PromoCode')  }} </h5>
                      <h4 class="mt-2">
                        {{ promocode }}
                        <v-btn class="ml-4" @click="promocodeDiscount = null ; promocode = null; " icon >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </h4>
                    </div>
                  </div>

                </div>
                <!-- Subscription course -->
                <div  class="mt-6 " v-if="entity.is_subscription">
                  <h4 :class="[{'text-center' : SM}]" class="mb-5" >{{ $t('SubscriptionPeriod') }}</h4>
                  <ws-select :items="timePeriodsSelect" v-model="enrollmentData.quantity" :placeholder="$t('Period')">  </ws-select>
                </div>
                <!-- Payment Plans course -->
                <div v-if="entity.has_payment_plans && !isSubscriptionExtend">
                  <h5 class="font-weight-medium my-3">{{ $t('ChoosePaymentPlan') }}:</h5>
                  <v-simple-table>
                    <tbody>
                    <tr v-for="(plan,i) in entity.payment_plans" :key="i"
                        @click="enrollmentData.payment_plan_id = plan.value"
                        class="pointer">
                      <td width="10px">
                        <v-btn @click="enrollmentData.payment_plan_id = plan.value"
                               :color="enrollmentData.payment_plan_id === plan.value ?  'green lighten-2' : 'grey' "
                               small icon
                               class="mt-1">
                          <v-icon small>{{ enrollmentData.payment_plan_id === plan.value ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <h5>{{ plan.text }}</h5>
                      </td>

                      <td width="10px" class="text-no-wrap" align="center">
                        <h4>{{ plan.price }} {{ BUSINESS_CURRENCY }}</h4>
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </div>
                <!-- Scheduled course -->
                <div v-if="entity.online && entity.scheduled && !isSubscriptionExtend">
                  <div v-if="!SM">
                    <h5 class="font-weight-medium mt-3">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
                    <v-simple-table >
                      <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th :style="`color : ${wsDARK}`"> {{ $t('Education Start') }}</th>
                        <th :style="`color : ${wsDARK}`"> {{ $t('Education End') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(wave,i) in enrollmentWaves" :key="i"
                          @click="enrollmentData.enrollment_wave_id = wave.uuid"
                          class="pointer">
                        <td>
                          <v-btn @click="enrollmentData.enrollment_wave_id = wave.uuid"
                                 :color="enrollmentData.enrollment_wave_id === wave.uuid ?  'green lighten-2' : 'grey' "
                                 small icon
                                 class="mt-1">
                            <v-icon small>{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <h5>{{ wave.name }}</h5>
                        </td>

                        <td>
                          <v-chip class="my-1 mr-2" small dark :color="wsDARK"  >
                            <v-icon small class="mr-1" > mdi-calendar</v-icon>
                            <h4 class="font-weight-medium">{{wave.education_start }}</h4>
                          </v-chip>
                        </td>
                        <td>
                          <v-chip class="my-1 mr-2" small outlined   >
                            <v-icon small class="mr-1" > mdi-calendar</v-icon>
                            <h4 class="font-weight-medium">{{wave.education_end }}</h4>
                          </v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                  <div v-else>
                    <h5 class="font-weight-medium mb-3 mt-6 text-center">{{ $t('ChooseEnrollmentSchedule') }}:</h5>
                    <v-sheet  v-for="(wave,i) in enrollmentWaves" :key="i"
                              @click="enrollmentData.enrollment_wave_id = wave.uuid"
                              :dark="enrollmentData.enrollment_wave_id === wave.uuid"
                              :color="enrollmentData.enrollment_wave_id === wave.uuid ? wsACCENT : ''"
                              class="pointer pa-3 mt-3 wsRoundedHalf" outlined>
                      <h5 class="text-center">{{ wave.name }}</h5>
                      <div class="d-flex justify-center">
                        <div>
                          <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                            <h5 class="font-weight-medium">{{ $t('EducationStart') }}: {{wave.education_start }}</h5>
                          </ws-chip>
                          <ws-chip icon="mdi-calendar" class="my-1 mr-2" small dark :color="enrollmentData.enrollment_wave_id !== wave.uuid ? wsACCENT : ''"  >
                            <h5 class="font-weight-medium">{{ $t('EducationEnd') }}: {{wave.education_end }}</h5>
                          </ws-chip>
                        </div>

                      </div>

                    </v-sheet>
                  </div>
                </div>

              </div>
              <div v-if="noDialog" class="d-flex justify-end pb-3 ">
                <v-btn
                    @click="purchaseCourse"
                    :color="wsATTENTION"
                    :block="SM"
                    class="noCaps"
                    dark
                >
                  <v-icon>mdi-basket-plus</v-icon>
                  {{ !isSubscriptionExtend ? $t('PurchaseCourse') : $t('ExtendSubscription') }}
                </v-btn>
              </div>
            </div>

          </wsDialog>
          <!-- Avalon -->
          <avalon-dialog
              v-model="displayPurchase"
              v-else-if="displayPurchase && avalon"
              :title="!isSubscriptionExtend ? $t('PurchaseCourse') : $t('ExtendSubscription')"
              no-navigation
              fullscreen
              fullscreen-width="550"
              fullscreen-align="right"
              fill-height
              not-centered
              :block="block"
              :block-style="block.config.style"
          >


            <template #default>
              <!-- Course Info -->
              <v-sheet  class="align-center " :class="[{'d-flex' : !SM }]"
                        :color="BLOCK_COLOR_PARAM(block , 'course-dialog_background', null)">
                <v-sheet v-if="entity.img" :width="!SM ? `190` : null">
                  <v-img  :width="!SM ? `190` : null" height="170"   :src="entity.img" ></v-img>
                </v-sheet>
                <h4 :style="BLOCK_TEXT_CSS( block , block.config.style , 'course-dialog' , 'title' )" class="px-5 py-3 ">{{ entity.name }}</h4>
              </v-sheet>

              <!-- Promocode -->
              <avalon-text-field
                  v-model="promocodeInput"
                  :label="$t('PromoCode') "
                  :placeholder="$t('EnterPromocode')"
                  :block="block"
                  :action-text="!promocode ? $t('Activate') : null"
                  @action="getPromoCode"
                  @clear="promocodeInput = null; promocodeDiscount = null ; promocode = null;"
                  class="mt-6"
              />
              <!-- Subscription -->
              <avalon-select-field
                  v-if="entity.is_subscription"
                  v-model="enrollmentData.quantity"
                  :items="timePeriodsSelect"
                  :label="$t('SubscriptionPeriod') "
                  :placeholder="$t('ChoosePeriod')"
                  :block="block"
                  :action-text="!promocode ? $t('Activate') : null"
                  @action="getPromoCode"
                  @clear="promocodeInput = null; promocodeDiscount = null ; promocode = null;"
                  class="mt-6"
              />
              <!-- Payment Plan -->
              <div v-if="entity.has_payment_plans " class="mt-6">
                <h5 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'label' )">
                  {{  $t('ChoosePaymentPlan')  }}
                </h5>

                <v-sheet :color="BLOCK_COLOR_PARAM(block , 'form_background', null)" :style="`border : 1px solid ${BLOCK_COLOR_PARAM(block , 'form_border', null)} !important`" class=" py-1 mb-6">

                  <template v-for="(plan,i) in entity.payment_plans">
                    <div  :key="i + 'plan'"
                          @click="enrollmentData.payment_plan_id = plan.value"
                          class="pointer d-flex align-center py-1 px-3">
                      <v-icon size="16" :color="BLOCK_COLOR_PARAM(block , 'form_icon', null)" class="mr-4">{{ enrollmentData.payment_plan_id === plan.value ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'}}</v-icon>
                      <div>
                        <h5 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'text' )">{{ plan.text }}</h5>
                        <h4 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'text_large' )">{{ plan.price }} {{ BUSINESS_CURRENCY }}</h4>
                      </div>
                    </div>
                    <v-divider :style="`border-color : ${BLOCK_COLOR_PARAM(block , 'form_border', null ) + '66'}`" v-if="i < entity.payment_plans.length - 1"  :key="i + 'divider'" />
                  </template>

                </v-sheet>

              </div>
              <!-- Enrollment Wave -->
              <div v-if="entity.online && entity.scheduled">
                <h5 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'label' )">
                  {{  $t('ChooseEnrollmentSchedule')  }}
                </h5>

                <v-sheet  :color="BLOCK_COLOR_PARAM(block , 'form_background', null)" :style="`border : 1px solid ${BLOCK_COLOR_PARAM(block , 'form_border', null)} !important`" class="py-1 mb-6">

                  <template v-for="(wave,i) in enrollmentWaves">
                    <div  :key="i + 'wave'"
                          @click="enrollmentData.enrollment_wave_id = wave.uuid"
                          class="pointer d-flex align-center py-1 px-3">
                      <v-icon size="16" :color="BLOCK_COLOR_PARAM(block , 'form_icon', null)" class="mr-4">{{ enrollmentData.enrollment_wave_id === wave.uuid ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'}}</v-icon>
                      <div>
                        <h5 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'text' )">{{ wave.name }}</h5>
                        <h4 :style="BLOCK_TEXT_CSS( block , block.config.style , 'form' , 'text_large' )">{{ PARSE_DATE(wave.education_start)  }} - {{ PARSE_DATE(wave.education_end) }}</h4>
                      </div>
                    </div>
                    <v-divider :style="`border-color : ${BLOCK_COLOR_PARAM(block , 'form_border', null ) + '66'}`"  v-if="i < enrollmentWaves.length - 1"  :key="i + 'wave_divider'" />
                  </template>

                </v-sheet>
              </div>

            </template>

            <template #footer>
              <v-divider :style="`border-color : ${BLOCK_COLOR_PARAM(block , 'course-dialog_line', null)}`" />
              <div style="padding: 15px 25px">
                <div>
                  <h4 v-if="discountQuantity > 0 && !(entity.has_payment_plans && !enrollmentData.payment_plan_id)"
                      :style="BLOCK_TEXT_CSS( block , block.config.style , 'course-dialog' , 'discount' )"
                      class="text-center" style="text-decoration: line-through">{{ $t('OldPrice') }}: {{ totalPriceText }} </h4>
                  <h4 style="opacity: 0.95" v-if="discountQuantity > 0" class="text-center" :style="BLOCK_TEXT_CSS( block , block.config.style , 'course-dialog' , 'discount' )" >{{ $t('Discount') }}: {{ discountQuantity }} % </h4>
                </div>
                <h4 :style="BLOCK_TEXT_CSS( block , block.config.style , 'course-dialog' , 'total_price' )"  class="text-center" >
                  {{ $t('Total') }}: {{ totalPriceTextDiscount }}
                </h4>

                <v-sheet :height="!SM ? `60` : `40`" class="mt-5">
                  <avalon-button
                      @click="purchaseCourse"
                      :block-style="block.config.style"
                      fillArea
                      :text="purchaseText"
                  />
                </v-sheet>
              </div>
            </template>

          </avalon-dialog>

          <!--Auth  Window -->
          <wsDialog v-model="displayAuth"
                    v-if="displayAuth"
                    :is="noDialog ? 'v-sheet' : 'ws-dialog'"
                    :class="noDialog ? `wsRoundedHalf pa-3` : null"
                    :width="!SM ? '500' : null"
                    :loading="$store.state.LOADING"
                    @save="purchaseCourse"
                    :title="$t('Authorization')"
                    no-navigation
                    :show-save="false" >

            <slot name="auth_header"></slot>

            <div  class="fill-height d-flex align-center">
              <div style="width: 100%" class="px-3">
                <h5 class="text-center font-weight-light mt-3"> {{ $t('ToContinuePleaseLoginOrRegisterAccount') }}</h5>
                <auth-window-new
                    @success="authFinished"
                    class="mt-3"
                    width="100%"
                    no-auth-action
                    display-register
                    :noToggle="true"
                    :element-uuid="entity.uuid + '/' + elementUuid"
                />
              </div>
            </div>


          </wsDialog>
        </div>
      </portal>


    </div>
  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import authWindow from "@/components/auth/authWindow";
import AuthWindowNew from "@/components/auth/authWindowNew";

export default {
  name: "viewButtonCourseEnroll",
  props : {
    display : {
      type : Number,
      default : 0
    },
    entity : {
      type : Object,
      default() { return {} }
    },
    studentProfile : {
      type : Object,
      default() { return {} }
    },
    isSubscriptionExtend : {
      type : Boolean,
      default : false
    },
    buyCourse : {
      type: Boolean,
      default : false
    },
    orderId : {},
    noDialog : {
      type : Boolean,
      default : false
    },
    avalon : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return { config : {} }}
    },
    noPortal : {
      type : Boolean,
      default : false
    },
    elementUuid : {
      type : String
    }
  },
  components : {
    AuthWindowNew,
    authWindow
  },
  data() {
    return {
      displayEnrollSuccess : false,
      displayEnroll : false,
      displayPurchase : false,
      displayAuth : false,
      enrollmentData : {},
      enrollmentWaves : [],
      displayLogin : true,
      displayApplicationReceivedText : false,
      promocodeDiscount : null,
      promocode : null,
      promocodeInput : ''
    }
  },
  computed : {
    ...mapState('auth', [  'loggedIn'  ]),
    uuid() {
      return this.entity.uuid
    },
    coursePrice() {
      if ( this.$store.state.avalon.editorMode) {
        return this.$t('Register')
      }
      if ( !this.entity ) {
        return
      }
      if ( !this.entity.payed ) {
        return this.$t('Register')
      }

      let price = (this.entity.has_payment_plans ? this.entity.min_price : this.entity.price)
          - Math.round((this.entity.discount || 0)/100*(this.entity.has_payment_plans ? this.entity.min_price : this.entity.price))

      let text = price + ' ' + this.BUSINESS_CURRENCY
          if ( this.entity.has_payment_plans ) {
            text =  this.$t('priceFrom') + ' ' + text
          }
      return this.$t('Purchase') + ' (' + text + ')'
    },

    isDashoard() {
      return this.$route.path.includes('dashboard/') || this.$route.path.includes('course/enroll/')
    },

    subscriptionTill() {
      if ( !this.isSubscriptionExtend ) {
        return null
      }
      if ( !this.studentProfile.uuid ) {
        return null
      }
      let currentDate = new Date()
      let date = this.studentProfile.subscription_date ? new Date(this.studentProfile.subscription_date) : currentDate
      date = date < currentDate ? currentDate : date

      if ( this.entity.time_period_type === 'month' ) {
        date = new Date( date.setMonth(date.getMonth() + this.enrollmentData.quantity) )
      }
      if ( this.entity.time_period_type === 'year' ) {
        date = new Date( date.setFullYear(date.getFullYear() + this.enrollmentData.quantity) )
      }
      if ( this.entity.time_period_type === 'custom' ) {
        date = new Date( date.setDate(date.getDate() + (this.enrollmentData.quantity*this.course.time_period_days )) )
      }

      return this.PARSE_DATE(date)

    },

    timePeriodsSelect() {
      if ( !this.entity.time_periods_select) {
        return []
      }
      let items = []

      this.entity.time_periods_select.forEach((period)=>{
        let type = this.entity.time_period_type
        let quantity = type === 'custom' ? (period * this.entity.time_period_days ) : period
        type = type === 'custom' ? 'day' : type

        let text = this.$tc(type , quantity);
        let periodDiscount = this.getPeriodDiscount(period)
        if ( periodDiscount && periodDiscount > this.entity.discount && periodDiscount > this.promocodeDiscount ) {
          text = text + ` (${this.$t('Discount')} : ${this.getPeriodDiscount(period) }%)`
        }

        items.push({
          text : text ,
          value : period
        })
      })
      return items
    },

    purchaseText() {
      return this.HAS_ONLINE_PAYMENT ?  this.$t('Buy') : this.$t('ApplyOnCourse')
    },
    lowestPrice() {
      if ( this.entity.has_payment_plans && this.entity.payment_plans.length > 0  ) {
        let prices = this.entity.payment_plans.map(el => el.price )
        return Math.min(...prices)
      }
      return 0;
    },

    singlePriceText() {

      let price = this.entity.price
      if ( this.entity.has_payment_plans) {
        if (this.enrollmentData.payment_plan_id) {
          let entity = this.entity.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = entity.price
        } else {
          return this.$t('SelectPaymentPlan')
        }
      }

      if ( this.entity.discount ) {
        price = price - Math.round(this.entity.discount/100*price)
      }

      return price + ' ' +  this.BUSINESS_CURRENCY

    },
    totalPriceTextDiscount() {
      if ( this.entity.has_payment_plans && !this.enrollmentData.payment_plan_id ) {
        return this.$t('SelectPaymentPlan')
      }
      return this.totalPriceDiscount + ' ' +  this.BUSINESS_CURRENCY
    },
    totalPriceText() {
      if ( this.entity.has_payment_plans && !this.enrollmentData.payment_plan_id ) {
        return this.$t('SelectPaymentPlan')
      }
      return this.totalPrice + ' ' +  this.BUSINESS_CURRENCY
    },
    priceDescriptionSubscriptionText() {
      if ( !this.entity.is_subscription ) {
        return ''
      }

      if ( this.entity.subscription_type === 'time_period' ) {
        if ( this.entity.time_period_type === 'month' ) {
          return ' ' + this.$tc('perMonths' , this.entity.time_period_days)
        }
        if ( this.entity.time_period_type === 'year' ) {
          return ' ' + this.$tc('perYears' , this.entity.time_period_days)
        }
        if ( this.entity.time_period_type === 'custom' ) {
          return ' ' + this.$tc('perDays' , this.entity.time_period_days)
        }
      }

      return ''

    },
    totalPrice() {

      let price = this.entity.price
      if ( this.entity.has_payment_plans) {
        if ( this.enrollmentData.payment_plan_id ) {
          let entity = this.entity.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = entity.price
        }
      }

      if ( this.enrollmentData.quantity ) {
        price = price * this.enrollmentData.quantity
      }

      return price

    },
    discountQuantityNoPromocode() {
      let discount = 0

      if (this.entity.discount) {
        discount = this.entity.discount
      }

      if ( this.enrollmentData.quantity ) {
        let periodDiscount = this.getPeriodDiscount(this.enrollmentData.quantity)
        if ( periodDiscount && periodDiscount > discount ) {
          discount = periodDiscount
        }
      }

      return discount
    },
    discountQuantity() {
      let discount = this.discountQuantityNoPromocode

      if ( this.promocodeDiscount > discount ) {
        discount = this.promocodeDiscount
      }

      return discount
    },
    totalPriceDiscount() {

      let price = this.totalPrice

      if ( this.discountQuantity ) {
        price = price - Math.round(this.discountQuantity/100*price)
      }

      return price

    }

  },
  watch : {
    display() {
      this.processCourseEnrollment()
    },
    uuid() {
      setTimeout(() => {
        this.$emit('course-text' , this.coursePrice)
        this.handleAuthReturn()
      })
    }
  },
  methods : {
    ...mapActions('courses', [  'GET_COURSE_PUBLIC' , 'CHECK_STUDENT_ENROLLMENT', 'GET_COURSE_FULL', 'ENROLL_ON_COURSE' , 'GET_ENROLLMENT_WAVES_STUDENT' ]),
    ...mapActions('cart', [  'ORDER_COURSE' , 'GET_PROMOCODE' ]),
    ...mapActions('liqpay', ['GET_BUSINESS_BUTTON']),
    ...mapActions('monobank', ['GET_MONO_CHECKOUT_URL']),
    ...mapActions('fondy', ['GET_FONDY_CHECKOUT_URL']),
    ...mapActions('wayforpay', ['GET_WAYFORPAY_CHECKOUT_URL']),
    ...mapActions('stripe', ['GET_STRIPE_CHECKOUT_URL']),


    initStudentProfile() {
      if (!this.isSubscriptionExtend ) {
        return
      }
      if (this.entity.has_payment_plans ) {
        this.enrollmentData.payment_plan_id = this.studentProfile.payment_plan_id
      }
    },

    async getPromoCode() {
      let data = {
        code : this.promocodeInput,
        course_id : this.entity.uuid
      }
      let result = await this.GET_PROMOCODE(data)
      if ( !result ) {
        return this.notify(this.$t('PromoCodeNotFount') , 'warning')
      }
      this.promocodeDiscount = result.percent
      this.promocode = result.code
      this.notify(this.$t('PromoCodeFound') , 'success')

    },

    processCourseEnrollment() {

      if ( this.entity.enrolled && !this.buyCourse && !this.$store.state.avalon.editorMode ) {
        this.goToCourse()
      }
      if ( this.entity.payed ) {
        this.displayBuyCourse()
      } else {
        this.handleEnrollOnCourse()
      }
      localStorage.removeItem('avalon_refresh_element')
    },

    async authFinished() {

      let check = await this.CHECK_STUDENT_ENROLLMENT(this.entity.uuid);
      if ( check && check.is_enrolled) {
        this.displayAuth = false
        this.goToCourse()
        return this.notify(this.$t('ProfileAlreadyEnrolledOncCourse'))
      }

      this.displayAuth = false
      if ( !this.entity.payed ) {
        this.handleEnrollOnCourse()
      } else {
        this.displayBuyCourse()
      }

    },
    goToCourse() {
      this.$router.push(this.businessDashLink('courses/' + this.entity.uuid + '/student'))
    },
    async displayBuyCourse() {
      if (this.orderId) {
        this.processOnlinePayment({order_number : this.orderId})
        return
      }
      if ( !this.loggedIn ) {
        this.displayAuth = true
        return
      }

      if ( this.entity.scheduled ) {
        this.enrollmentData = {
          uuid : this.entity.uuid,
          enrollment_wave_id : this.studentProfile && this.studentProfile.enrollment_wave_id ? this.studentProfile.enrollment_wave_id : null
        }
        this.enrollmentWaves = await this.GET_ENROLLMENT_WAVES_STUDENT(this.entity.uuid) || [] ;
      }
      if ( this.entity.has_payment_plans ) {
        this.enrollmentData.payment_plan_id = this.studentProfile && this.studentProfile.payment_plan_id ? this.studentProfile.payment_plan_id : null
      }
      this.initStudentProfile()
      this.enrollmentData = JSON.parse(JSON.stringify(this.enrollmentData))

      this.displayPurchase = true
    },
    async purchaseCourse() {

      if ( this.$store.state.avalon.editorMode ) {
        this.notify(this.$t('avalon.links_disabled_in_editor') , 'warning')
        return
      }

      if ( this.entity.is_subscription && !this.enrollmentData.quantity) {
        this.notify(this.$t('ChooseSubscriptionPeriod') , 'warning')
        return
      }


      let data = {
        course_id : this.entity.uuid,
        origin : this.DEVICE
      }

      if (  this.entity.online && this.entity.scheduled ) {
        if ( !this.enrollmentData.enrollment_wave_id ) {
          return this.notify(this.$t('PleaseSelectEnrollmentDate'))
        }
        data.enrollment_wave_id = this.enrollmentData.enrollment_wave_id
      }

      if (  this.entity.payed && this.entity.has_payment_plans ) {
        if ( !this.enrollmentData.payment_plan_id ) {
          return this.notify(this.$t('PleaseSelectPaymentPlan'))
        }
        data.payment_plan_id = this.enrollmentData.payment_plan_id
      }

      if ( this.enrollmentData.quantity ) {
        data.quantity = this.enrollmentData.quantity
      }

      if ( this.promocodeDiscount &&  this.promocodeDiscount > this.discountQuantityNoPromocode ) {
        data.promocode = this.promocode
      }


      let result = await this.ORDER_COURSE(data)
      if ( !result ) { return this.notify( this.$t('NetworkError') ) }

      this.entity.enrolled = true

      if ( !result.free ) {
        this.processOnlinePayment(result)
      }

      this.displayPurchase = false
      this.displayEnrollSuccess = true;


    },
    async processOnlinePayment(order) {
      if ( !this.HAS_ONLINE_PAYMENT ) {
        this.displayPurchase = false
        if (!this.orderId) {
          this.displayEnrollSuccess = true;
        }
        return
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_liqpay') {
        this.getLiqpayButton(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_mono') {
        this.getMonoUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_fondy') {
        this.getFondyUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_wayforpay') {
        this.getWayforpayUrl(order)
      }
      if ( this.GET_ONLINE_PAYMENT === 'online_stripe') {
        this.getStripeUrl(order)
      }
    },

    async getMonoUrl(order) {

      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_MONO_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.pageUrl
      a.click()
      a.remove()

    },
    async getWayforpayUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_WAYFORPAY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      this.WAYFORPAY_INVOICE(result)
      this.displayEnroll = false
      if (!this.orderId) {
        this.displayEnrollSuccess = true
      }

    },
    async getFondyUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_FONDY_CHECKOUT_URL(data)
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },
    async getLiqpayButton(order) {

      // let route = this.$router.resolve(this.businessDashLink('courses/' + this.entity.uuid + '/student'))
      // const returnUrl = new URL(route.href, window.location.origin).href;

      let data = {
        order_number: order.order_number,
        total: this.totalPrice,
        return_url: this.businessDashLink('',false, true)
        //return_url: returnUrl
      }

      let button = await this.GET_BUSINESS_BUTTON(data)
      if ( !button || button === true ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.LIQPAY_checkout(button)
      this.displayEnroll = false
      if (!this.orderId) {
        this.displayEnrollSuccess = true
      }


    },
    async getStripeUrl(order) {
      let data = {
        order_number: order.order_number,
        return_url: this.businessDashLink('',false, true)
      }

      let result = await this.GET_STRIPE_CHECKOUT_URL(data,'stripe')
      if ( !result ) {
        return
      }
      let a = document.createElement('a')
      a.href = result.url
      a.click()
      a.remove()

    },

    handleEnrollOnCourse() {

      if ( !this.loggedIn ) {
        this.displayAuth = true
        return
      }

      this.enrollmentData = {
        uuid : this.entity.uuid
      }

      if ( !this.entity.online ) {
        this.enrollOnCourse()
      } else {
        this.displayEnrollWindow()
      }

    },
    async displayEnrollWindow() {

      if ( this.entity.online && this.entity.scheduled ) {
        this.enrollmentData = {
          uuid : this.entity.uuid,
          enrollment_wave_id : null
        }
        this.enrollmentWaves = await this.GET_ENROLLMENT_WAVES_STUDENT(this.entity.uuid) || [] ;
      }

      this.displayEnroll = true
    },

    async enrollOnCourse(displayEnroll = true) {


      if ( this.entity.online && this.entity.scheduled ) {
        if ( !this.enrollmentData.enrollment_wave_id ) {
          return this.notify(this.$t('PleaseSelectEnrollmentDate'))
        }
      }

      let result = await this.ENROLL_ON_COURSE(this.enrollmentData);
      if ( !result ) {
        if ( this.$store.state.ajax.error === 'Student already enrolled on course') {
          return this.goToCourse()
        }
        return this.notify(this.$t('Error'))
      }
      this.entity.enrolled = true

      if ( displayEnroll ) {
        this.displayEnroll = false
        this.displayEnrollSuccess = true
      }

    },

    //technical

    getPeriodDiscount(period) {
      if (!this.entity.periods_discount || this.entity.periods_discount.length === 0) {
        return null
      }
      let periodDiscount = this.entity.periods_discount.find(el => el.period === period )
      return periodDiscount ? periodDiscount.percent :  null
    },
    handleAuthReturn() {
      let elementRefreshAuth = localStorage.getItem('avalon_refresh_element')
      if ( !elementRefreshAuth ) {
        return
      }
      let split = elementRefreshAuth.split('/')
      let course = split[0]
      elementRefreshAuth = split[1]

      if (this.loggedIn && course && course === this.uuid && elementRefreshAuth && this.elementUuid && String(elementRefreshAuth) === String(this.elementUuid) ) {
        localStorage.removeItem('avalon_refresh_element')
        this.processCourseEnrollment()
      }
    }

  },
  mounted() {
    setTimeout(() => {
      this.$emit('course-text' , this.coursePrice)
    })
    this.handleAuthReturn()
  }
}
</script>

<style scoped>

</style>