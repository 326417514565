<template>
  <portal to="dialog">
    <ws-dialog
      v-model="display"
      overlay-color="transparent"
      width="320"
      no-navigation
      :title="$t('EditBlock')"
      title-icon="mdi-pencil"
      not-centered
      no-padding
      margin-right="25px"
      margin-top="68px"
      margin-bottom="25px"
      border-radius="8"
      blank
      height="90%"
      max-height="640px"
      :deselect-blind-zone-x="WINDOW_WIDTH - 345"
      fullscreen-align="right"
      :avalon-sidebar="!$store.state.avalon.imageLibraryOn"
  >

      <template #header>
        <ws-navigation-header
            style="width: 100%"
            v-model="navigation"
            :items="navigationSelect"
            no-number
            padding="20px"
            class="my-2"
        />
        <portal-target name="block_settings_return"></portal-target>
      </template>

      <template #default>
        <image-library-dialog
            @select="updateImage($event , true)"
            v-if="displayLibrary"
            v-model="displayLibrary"
        />
        <div class="pa-6">
          <!-- Content -->
          <div v-if="navigation === 'content'" >
            <component
                v-if="settingsComponent"
                :is="settingsComponent"
                v-model="block.config"
                :block="block"
                @input="updateBlock()"
            />
          </div>
          <!-- Format -->
          <div v-if="navigation === 'format'" >

            <block-settings-navigation
                v-if="hasFormat"
                v-model="selectedFormatCategory"
                :items="formatContentSelect"
                portal
            >
              <template #item.general>
                <block-general-settings
                    @input="updateBlock()"
                    v-model="block.config"
                    :block="block"
                    format
                />
              </template>
              <template #item.block>
                <component
                    v-if="hasFormat"
                    :is="settingsComponent"
                    v-model="block.config"
                    :block="block"
                    @input="updateBlock()"
                    format
                />
              </template>

            </block-settings-navigation>
            <block-general-settings
                v-else
                @input="updateBlock()"
                v-model="block.config"
                :block="block"
                format
            />

            <!-- Zero Block Grid Settings -->
            <div v-if="!MOBILE_VIEW && !block.type " class="pb-8 mt-8">

              <h4 style="font-size: 14px" :style="`color : ${wsDARKER}`"> {{ $t('format.block_grid') }}</h4>
              <ws-button-group
                  v-model="gridPaddingValue"
                  @input="changeGridGapPadding($event)"
                  :items="gridGapSelect"
                  class="mb-2 mt-2"
              />

              <v-expand-transition>
                <div v-if="gridPaddingValue === 'custom' ">
                  <a-parameter-slider
                      @change="updateBlock(); checkAutoPadding(); $emit('editing' , false)"
                      @input="$emit('editing' , true)"
                      v-model="block.config.gridGapHorizontal"
                      :track-color="wsDARKLIGHT"
                      :value-color="wsACCENT"
                      label="format.horizontal_padding"
                      min="0"
                      max="40"
                      light
                  />
                  <a-parameter-slider
                      @change="updateBlock(); checkAutoPadding(); $emit('editing' , false)"
                      @input="$emit('editing' , true)"
                      v-model="block.config.gridGapVertical"
                      :track-color="wsDARKLIGHT"
                      :value-color="wsACCENT"
                      label="format.vertical_padding"
                      min="0"
                      max="40"
                      class="mt-4"
                      light
                  />
                </div>
              </v-expand-transition>

              <a-parameter-slider
                  class="mt-3"
                  @change="updateBlock(); $emit('editing' , false)"
                  @input="$emit('editing' , true)"
                  v-model="block.config.columns"
                  :track-color="wsDARKLIGHT"
                  :value-color="wsACCENT"
                  label="Columns"
                  :min="maxWidth >= 11 ? maxWidth : 11"
                  :max="25"
                  light
              />

            </div>

          </div>
          <!-- Style -->
          <div v-if="navigation === 'style'">
            <ws-accordion
                :items="styleAccordionItems"
            >
              <template #item.design>
                <design-selector
                    v-if="elementsDesignConfig[block.type]"
                    v-model="block.config.design"
                    @input="updateBlock(); $store.state.avalon.selectedBlockDesign = block.config.design"
                    :type="block.type"
                    :block-style="block.config.style"
                />
                <component
                    v-else-if="settingsComponent"
                    :is="settingsComponent"
                    v-model="block.config"
                    :block="block"
                    @input="updateBlock()"
                    design
                />
              </template>
              <template #item.color>
                <block-style-settings
                    @input="updateBlock"
                    v-model="block.config"
                    :block="block"
                />
              </template>
              <template #item.fonts>
                <block-style-settings
                    @input="updateBlock"
                    v-model="block.config"
                    :block="block"
                    fonts
                />
              </template>
              <template #item.image>
                <div class="pa-5">
                  <div  v-if="block.config.image">
                    <v-sheet
                        :color="wsLIGHTCARD"
                        class="wsRoundedLight"
                    >
                      <v-img
                          :src="block.config.image"
                          class="wsRoundedLight"
                          aspect-ratio="1.5"
                      />
                      <div class="py-1">
                        <v-btn @click="block.config.image=null; block.config.background_type='color'; updateBlock()" text class="noCaps" :color="wsACCENT">
                          <v-icon class="mr-2">mdi-delete-outline</v-icon>
                          {{ $t('Delete') }}
                        </v-btn>
                      </div>
                    </v-sheet>

                    <a-parameter-slider
                        @change="updateOpacity($event)"
                        :value="100 - block.config.overlay_opacity"
                        :default="20"
                        label="Opacity"
                        :track-color="wsDARKLIGHT"
                        :value-color="wsACCENT"
                        class="mt-4"
                        min="0"
                        max="100"
                    />
                    <h4 :style="`color : ${wsDARKER}`" class="mt-10">{{ $t('ImageDisposition') }}</h4>
                    <div v-if="!MOBILE_VIEW" >
                      <a-parameter-slider
                          @change="updateBlock"
                          v-model="block.config.image_position_x"
                          :default="avalonConfig.block.width"
                          :label="$t('Horizontal')"
                          :track-color="wsDARKLIGHT"
                          :value-color="wsACCENT"
                          class="mt-4"
                          min="1"
                          max="100"
                      />
                      <a-parameter-slider
                          @change="updateBlock"
                          v-model="block.config.image_position_y"
                          :track-color="wsDARKLIGHT"
                          :value-color="wsACCENT"
                          :default="avalonConfig.block.width"
                          :label="$t('Vertical')"
                          class="mt-4"
                          min="1"
                          max="100"
                      />
                    </div>
                    <div v-if="MOBILE_VIEW" >
                      <a-parameter-slider
                          @change="updateBlock"
                          v-model="block.config.image_position_sm_x"
                          :default="avalonConfig.block.width"
                          :track-color="wsDARKLIGHT"
                          :value-color="wsACCENT"
                          label="Horizontal"
                          class="mt-4"
                          min="1"
                          max="100"
                      />
                      <a-parameter-slider
                          @change="updateBlock"
                          v-model="block.config.image_position_sm_y"
                          :default="avalonConfig.block.width"
                          :track-color="wsDARKLIGHT"
                          :value-color="wsACCENT"
                          label="Vertical"
                          class="mt-4"
                          min="1"
                          max="100"
                      />
                    </div>

                  </div>
                  <ws-file-uploader
                      v-else
                      @success="updateImage($event)"
                      disable-full-click
                      image
                      public
                  >
                    <template v-slot="{selectFile}">
                      <v-sheet width="50" class="mx-auto" color="transparent">
                        <ft-select
                            @input="addImageAction($event, selectFile)"
                            :items="addImageActionsSelect"
                        >
                          <v-btn class="mx-auto"  :color="wsATTENTION" icon>
                            <v-icon large   :color="wsATTENTION">mdi-plus-circle</v-icon>
                          </v-btn>
                        </ft-select>
                      </v-sheet>

                      <h5 :style="`color : ${wsACCENT}`"
                          style="max-width: 180px"
                          class="mt-2 mx-auto"
                      >
                        {{ $t('uploader.choose_image') }}
                      </h5>

                    </template>
                  </ws-file-uploader>
                </div>
              </template>

            </ws-accordion>
          </div>
        </div>
      </template>

      <template #footer>
        <v-sheet class="px-6 pt-6 pb-3">
          <portal-target name="block_settings_footer"></portal-target>
          <ws-button
              @click="resetChanges"
              :disabled="!resetActive"
              label="ResetSettings"
              height="40"
              block
              outlined
          />
          <h5
              @click="CLIPBOARD(`#${block.id}`)"
              :style="`color:${wsDARKLIGHT}`"
              class="mt-3 d-flex justify-center align-center pointer"
          >
            #{{ block.id }}
          <v-icon
              :color="wsDARKLIGHT"
              class="ml-1"
              size="20"
          >
            mdi-content-copy
          </v-icon>
          </h5>
        </v-sheet>
      </template>
  </ws-dialog>
  </portal>

</template>

<script>
import elementsDesignConfig from "@/assets/json/avalon/elements_design_config.json"

import designSelector from "@/components/AvalonEditor/Dialogs/UI/designSelector";
import imageLibraryDialog from "@/components/AvalonEditor/Dialogs/imageLibraryDialog";

import blockTilesCoursesSettings from "@/components/AvalonEditor/Dialogs/blocks/blockTilesCoursesSettings";
import blockGeneralSettings from "@/components/AvalonEditor/Dialogs/blocks/blockGeneralSettings";
import blockStyleSettings from "@/components/AvalonEditor/Dialogs/blocks/blockStyleSettings";
import blockGallerySettings from "@/components/AvalonEditor/Dialogs/blocks/blockGallerySettings";
import blockSliderSettings from "@/components/AvalonEditor/Dialogs/blocks/blockSliderSettings";
import blockNavigationSettings from "@/components/AvalonEditor/Dialogs/blocks/blockNavigationSettings";
import blockTilesSettings from "@/components/AvalonEditor/Dialogs/blocks/blockTilesSettings";
import blockTilesNewsSettings from "@/components/AvalonEditor/Dialogs/blocks/blockTilesNewsSettings";
import blockSettingsNavigation from "@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation";
import blockCoursePricingSettings from "@/components/AvalonEditor/Dialogs/blocks/blockCoursePricingSettings";
import blockTextSettings from "@/components/AvalonEditor/Dialogs/blocks/blockTextSettings";

export default {
  name: "avalonBlocSettings",
  components : {
    imageLibraryDialog         ,
    blockGeneralSettings       ,
    blockStyleSettings         ,
    blockGallerySettings       ,
    blockSliderSettings        ,
    blockNavigationSettings    ,
    blockTilesSettings         ,
    blockTilesNewsSettings     ,
    blockSettingsNavigation    ,
    blockTilesCoursesSettings  ,
    blockCoursePricingSettings ,
    blockTextSettings          ,
    designSelector
  },
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    block : {
      type : Object,
      default() { return {} }
    },
    maxHeight : {
      type : Number,
      default : 0,
    },
    maxHeightSM : {
      type : Number,
      default : 0,
    },
    maxWidth : {
      type : Number,
      default : 0,
    },

  },
  data() {
    return {
      navigation : 'format',
      autoPadding : 'm',
      displayLibrary : false,
      display : false,
      preventClose : false,
      blockBuffer : null,
      resetActive : false,
      gridPaddingValue : 'custom',
      selectedFormatCategory : null,
      elementsDesignConfig : {},
      styleAccordionItems : []
    }
  },
  computed : {
    formatContentSelect() {

      let items =  [
        { text: this.$t('format.block_general'), value: 'general' },
      ]

      if ( this.hasFormat ) {
        items.push( { text : this.$t(`format.block_${this.block.type}`) , value : 'block' } )
      }

      return items
    },
    hasFontsVariables() {

      if ( !this.block.type ) {
        return false
      }

      if ( !this.$store.state.avalon.blocksConfig[this.block.type] ) {
        return false
      }
      if ( !this.$store.state.avalon.blocksConfig[this.block.type]['variables'] ) {
        return false
      }

      return !!this.$store.state.avalon.blocksConfig[this.block.type]['variables']['fonts']

    },
    settingsComponent() {

      switch(this.block.type) {
        case 'gallery'       : return 'blockGallerySettings'
        case 'slider1'       : return 'blockSliderSettings'
        case 'tiles'         : return 'blockTilesSettings'
        case 'tiles_news'    : return 'blockTilesNewsSettings'
        case 'tiles_courses' : return 'blockTilesCoursesSettings'
        case 'course-pricing': return 'blockCoursePricingSettings'
        case 'block-text'          : return 'blockTextSettings'
        case 'navigation'    : return 'blockNavigationSettings'
        default : return null
      }

    },
    hasFormat() {
      switch(this.block.type) {
        case 'gallery'    : return true
        case 'tiles'    : return true
        case 'block-text'    : return true
        case 'tiles_news'    : return true
        case 'tiles_courses' : return true
        case 'course-pricing' : return true
        default : return false
      }
    },
    hasDesign() {
      if ( this.elementsDesignConfig[this.block.type]) {
        return true
      }
      switch(this.block.type) {
        case 'slider1'    : return true
        case 'tiles_news'    : return true
        case 'tiles_courses' : return true
        case 'gallery' : return true
        default : return false
      }
    },
    gridGapSelect() {
      return [
        { icon : 'mdi-border-all' , value : 'none' },
        { icon : 'mdi-view-grid-outline' , value : 'default' },
        { icon : 'mdi-dots-horizontal' , value : 'custom' }
      ]
    },
    addImageActionsSelect() {
      return [
        { text : this.$t('Upload') , value : 'upload' , icon : 'mdi-upload' },
        { text : this.$t('BrowseCollection') , value : 'browse' , icon : 'mdi-grid' }
      ]
    },
    navigationSelect() {
      let items =  [
        { text : this.$t('Content') , value : 'content' },
        { text : this.$t('Format') , value : 'format' },
        { text : this.$t('Style')   , value : 'style'   }
      ]

      if ( !this.block.type ) {
        items.splice(0,1)
      }


      return items
    },
    backgroundTypes() {
      return [
        { text : this.$t('Color') , value : 'color' , icon : 'mdi-palette'},
        { text : this.$t('Image') , value : 'image' , icon : 'mdi-image'},
        // { text : this.$t('Video') , value : 'video' , icon : 'mdi-video'},
        // { text : this.$t('None')  ,  value : null   , icon : 'mdi-cancel'}
      ]
    },
    design() {
      return this.block.config.design
    }
  },
  watch : {
    design(value ) {
      if ( this.block.type === 'gallery') {

        this.block.config.slide_width_percent = value !== 'slider_wide'  ?  null : 100

        this.updateBlock()
      }
    },
    MOBILE_VIEW() {
      this.checkAutoPadding()
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input',this.display)
      }
    }
  },
  methods : {
    getStyleAccordionItems() {
      let items =  [
        { name : this.$t('Color') , value : 'color' , expanded : false} ,
        { name : this.$t('BackgroundImage') , value : 'image', expanded : false}]

      if ( this.hasFontsVariables ) {
        items.splice(1,0, { name : this.$t('Fonts') , value : 'fonts' , expanded : false}  )
      }

      if ( this.hasDesign ) {
        items.unshift(  { name : this.$t('Design') , value : 'design' , expanded : false} )
      }

      this.styleAccordionItems = items
    },
    checkPaddingSelect() {
      if ( this.block.config.gridGapHorizontal === 8 && this.block.config.gridGapVertical === 8 ) {
        this.gridPaddingValue = 'default'
      }
      else if ( !this.block.config.gridGapHorizontal && !this.block.config.gridGapVertical ) {
        this.gridPaddingValue = 'none'
      }
      else {
        this.gridPaddingValue = 'custom'
      }

    },
    changeGridGapPadding(value) {


      if ( value === 'custom' ) {
        return
      }
      if ( value === 'none' ) {
        this.block.config.gridGapHorizontal = 0
        this.block.config.gridGapVertical = 0
      }
      if ( value === 'default' ) {
        this.block.config.gridGapHorizontal = 8
        this.block.config.gridGapVertical = 8
      }

      this.updateBlock();
    },
    resetChanges() {
      this.block = this.COPY(this.blockBuffer)
      this.updateBlock()
      this.resetActive = false
    },
    updateOpacity(value ) {
      this.block.config.overlay_opacity = 100 - value
      this.block = this.COPY(this.block)
      this.updateBlock()
    },
    checkAutoPadding() {
      if ( !this.MOBILE_VIEW ) {
        if ( this.block.config.padding_top === 10 && this.block.config.padding_bottom === 10 ) {
          this.autoPadding = 'm'
        }
        else if ( this.block.config.padding_top === 75 && this.block.config.padding_bottom === 75 ) {
          this.autoPadding = 'l'
        }
        else if ( this.block.config.padding_top === 150 && this.block.config.padding_bottom === 150 ) {
          this.autoPadding = 'xl'
        }
        else {
          this.autoPadding = null
        }
      } else {
        if ( this.block.config.padding_top_sm === 10 && this.block.config.padding_bottom_sm === 10 ) {
          this.autoPadding = 'm'
        }
        else if ( this.block.config.padding_top_sm === 75 && this.block.config.padding_bottom_sm === 75 ) {
          this.autoPadding = 'l'
        }
        else if ( this.block.config.padding_top_sm === 150 && this.block.config.padding_bottom_sm === 150 ) {
          this.autoPadding = 'xl'
        }
        else {
          this.autoPadding = null
        }
      }
    },
    addImageAction(value , selectFunction) {
      if ( value === 'upload') {
        selectFunction()
        return
      }

      this.displayLibrary = true
    },
    editBlockStyle(style) {
      this.block.config.overlay_color = this.CONFIG_PARAM('styles',style , 'bg')
      this.block.config.style = style
      this.updateBlock()
    },
    updateImage($event , isString = false) {
      this.block.config.overlay_opacity = 70
      this.block.config.overlay_color = this.block.config.color
      this.block.config.background_type = 'image'
      this.block.config.image = !isString ?  $event.url : $event
      this.updateBlock()
    },
    async updateBlock() {
      this.resetActive = true
      this.block = JSON.parse(JSON.stringify(this.block))
      this.$store.state.avalon.rowHeightChangeTrigger++
      this.$emit('update',this.block)
    }
  },
  beforeMount() {
    this.blockBuffer = this.COPY(this.block)
    this.getStyleAccordionItems()
  },
  mounted() {
    this.elementsDesignConfig = elementsDesignConfig
    if ( !this.$store.state.avalon.menuNavigationStyleTheme ) {
      this.$store.state.avalon.menuNavigationStyleTheme = this.block.config.style
    }
    this.$store.state.avalon.selectedBlockType = this.block.type || 'block'
    this.$store.state.avalon.selectedBlockStyle = this.block.config.style || null
    this.$store.state.avalon.selectedBlockDesign = this.block.config.design || null
    this.checkPaddingSelect()
    if ( this.block.type ) {
      this.navigation = 'content'
    }
    this.checkAutoPadding();
    this.display = this.value
    if (!this.block.config) {
      this.block.config = {
        background_type : this.avalonConfig.block.background_type,
        full_width : this.avalonConfig.block.full_width,
        vertical_padding : this.avalonConfig.block.vertical_padding
      }
    }

    if ( ![true,false].includes(this.block.config.visibility_mobile)) {
      this.block.config.visibility_mobile = true
    }
    if ( ![true,false].includes(this.block.config.visibility_desktop)) {
      this.block.config.visibility_desktop = true
    }
    this.block.config = Object.assign({},this.block.config)

  },
  beforeDestroy() {
    this.$store.state.avalon.selectedElementDesign = null
    this.$store.state.avalon.selectedElementType = null
    this.$store.state.avalon.selectedElementStyle = null
    this.$store.state.avalon.selectedBlockStyle = null
    this.$store.state.avalon.selectedBlockType = null
    this.$store.state.avalon.selectedBlockDesign = null
  }
}
</script>

<style scoped>
.block-style {
  position:fixed;
  outline: none;
  top:0;bottom: 0;
  right: 0;
  height: 100% ;
  z-index:1111
}
</style>