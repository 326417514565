<template>
  <div   v-if="!($store.state.avalon.previewMode || $store.state.avalon.sideMenu)" >

    <v-sheet color="transparent" v-if="header.config.sticky" :height="header.config.height" />
    <!-- Editor -->
    <v-sheet

        min-height="600"
        :color="CONFIG_PARAM_COLOR('styles' , pageStyle , 'bg' )"
        style="width: 100%" class="d-flex justify-center py-6 pb-12">
      <v-sheet color="transparent"  style="max-width: 800px; width: 100%">
        <v-textarea
            v-model="entity.title"
            @change="saveNews"
            :placeholder="$t('EnterNewsTitle')"
            class="pa-0 px-5 ma-0 heading text-center hoverUnderline title"
            style="font-weight: 600; font-size: 42px"
            background-color="transparent"
            row-height="10"
            ref="heading"
            hide-details
            single-line
            auto-grow
            dense
            flat
            solo
        />
        <wsTextEditor
            v-model="entity.content"
            @input="saveNews"
            :placeholder="$t('EnterNewsContent')"
            is-public
        />
      </v-sheet>
    </v-sheet>
    <!-- View -->


  </div>
  <view-news
      v-else
      :alias="alias"
      :news="entity"
  />

</template>

<script>
import {mapActions, mapState} from "vuex";
import viewNews from "@/components/AvalonEditor/View/viewNews";

export default {
  name: "avalonNewsEditor",
  components : {
    viewNews
  },
  props : {
    alias : {
      type : String
    },
    publishTrigger : {
      type : Number,
      deafult : 0
    }
  },
  data() {
    return {
      entity : {}
    }
  },
  computed : {
    ...mapState('avalon' , [
      'selectedNews' ,
      'selectedLang' ,
      'sideMenu' ,
      'editorMode',
      'previewMode',
      'header'
    ]),
    pageStyle() {
      return 'light_1'
    },
    languagesSelect() {
      let items = []

      this.AVALON_LANGS.forEach(lang=>{
        items.push({text: lang.toUpperCase(), value: lang})
      })

      return items
    }

  },
  watch : {
    alias() {
      this.initPage()
    },
    publishTrigger() {
      this.publish()
    },
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('news', [
          'GET_ADMIN_BUSINESS_NEWS_ONE',
          'ADD_EDIT_BUSINESS_NEWS',
        ]
    ),

    async publish() {
      this.entity.published = true
      await this.saveNews()
      this.notify(this.$t('NewsPublished') , 'success')
    },

    async saveNews() {
      let data = this.COPY(this.entity)
      data.lang = this.selectedLang

      let result = await this.ADD_EDIT_BUSINESS_NEWS(data)
      if (!result) { return }
      let index = this.$store.state.avalon.newsSelect.findIndex(el => el.uuid === result.uuid )
      if ( index === -1 ) {
        return
      }
      this.$store.state.avalon.newsSelect[index] = result
      this.$store.state.avalon.newsSelect = this.COPY(this.$store.state.avalon.newsSelect)
    },

    async initPage() {
      if ( !this.alias ) { return }
      this.entity = await this.GET_ADMIN_BUSINESS_NEWS_ONE({alias : this.alias , lang : this.selectedLang})
      if ( !this.entity.content ) {
        this.entity.content = []
      }
      this.entity = this.COPY(this.entity)

    },

    toggleSideMenu() {
      this.$store.state.avalon.sideMenu = !this.$store.state.avalon.sideMenu
    },
    async togglePreviewMode(value) {
      if ( !value ) {
        this.$router.push(this.businessPublicLink('page/' + this.entity.id + '/editor'))
      }
      this.$store.state.avalon.sideMenu = !value
      this.$store.state.avalon.mobileView = false
      this.$store.state.avalon.previewMode = value
    },

    shortenText(text = '' ) {
      if ( text.length > 60 ) {
        return text.slice(0,60) + ' ...'
      }
      return text
    },

    parseTexfieldsStyle() {
      let el = this.$refs.heading.$el
      let headerStyle = el.querySelector('textarea');
      headerStyle.style.color = this.wsDARKER
      headerStyle.style.fontSize = "42px"
      headerStyle.style.lineHeight = '50.4px'
    }


  },
  async mounted(){
    await this.initPage()
    this.parseTexfieldsStyle()

  }
}
</script>

<style scoped>

</style>